// components/VideoLibrary/core/VideoLibraryNavigation.tsx
import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { motion, Variants } from "motion/react";
import ContentWrapper from "@/components/Core/ContentWrapper";
import { useVideoPlayerStore } from "../store/videoPlayerStore";
import VideoFilter from "../navigation/VideoFilter";
import VideoGrid from "../navigation/VideoGrid";
import VideoLibraryControlButton from "../ui/VideoLibraryControlButton";
import VideoPauseIcon from "@/assets/svg/videoPause.svg";
import VideoPlayIcon from "@/assets/svg/videoPlay.svg";
import mq from "@/utils/mq";

const VideoLibraryNavigationControlsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 100;
  position: relative;
  padding-bottom: 16px;
  ${mq.mobile(css`
    justify-content: flex-start;
    padding-top: 46px;
  `)};
`;

const ControlsWrapper = styled(motion.div)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  ${mq.mobile(css`
    gap: 8px;
  `)};
`;

const AnimationControlsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
`;

// Animation variants
const controlsVariants: Variants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.12,
      delayChildren: 0.1,
    },
  },
};

const controlItemVariants: Variants = {
  hidden: {
    opacity: 0,
    y: 10,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
      ease: [0.25, 0.1, 0.25, 1.0],
    },
  },
};

interface VideoLibraryNavigationProps {
  videoLibrary: Array<{
    content: any;
    full_slug: string;
    name: string;
  }>;
  showAnimationControls?: boolean;
}

/**
 * Component that manages the video library navigation, filtering, and grid display
 */
export default function VideoLibraryNavigation({
  videoLibrary,
  showAnimationControls = false,
}: VideoLibraryNavigationProps) {
  // Animation state
  const [animationKey, setAnimationKey] = useState(0);
  const [autoAnimate, setAutoAnimate] = useState(false);

  // Store state
  const { playBackgroundVideo, setPlayBackgroundVideo } = useVideoPlayerStore();

  // Animation control functions
  const resetAnimation = () => {
    setAnimationKey((prevKey) => prevKey + 1);
  };

  const toggleAutoAnimate = () => {
    setAutoAnimate((prev) => !prev);
  };

  // Auto animation effect
  useEffect(() => {
    let intervalRef: NodeJS.Timeout | null = null;

    if (autoAnimate) {
      intervalRef = setInterval(resetAnimation, 3000);
    }

    // Clean up interval on component unmount or when autoAnimate changes
    return () => {
      if (intervalRef) {
        clearInterval(intervalRef);
      }
    };
  }, [autoAnimate]);

  // Toggle background video playback
  const toggleBackgroundVideo = () => {
    setPlayBackgroundVideo(!playBackgroundVideo);
  };

  if (!videoLibrary || videoLibrary.length === 0) {
    return null;
  }

  return (
    <ContentWrapper backgroundColor='#090B0B'>
      <VideoLibraryNavigationControlsContainer>
        {showAnimationControls && (
          <AnimationControlsWrapper>
            <VideoLibraryControlButton label='Reset Animation' action={resetAnimation} />
            <VideoLibraryControlButton
              label={autoAnimate ? "Auto: ON" : "Auto: OFF"}
              action={toggleAutoAnimate}
              style={{
                background: autoAnimate ? "rgba(103, 232, 249, 0.2)" : undefined,
                color: autoAnimate ? "#67e8f9" : undefined,
                border: autoAnimate ? "1px solid rgba(103, 232, 249, 0.4)" : undefined,
              }}
            />
          </AnimationControlsWrapper>
        )}

        <ControlsWrapper variants={controlsVariants} initial='hidden' animate='visible'>
          <motion.div variants={controlItemVariants}>
            <VideoFilter />
          </motion.div>
          <motion.div variants={controlItemVariants}>
            <VideoLibraryControlButton
              label={playBackgroundVideo ? "Pause Background" : "Play Background"}
              icon={playBackgroundVideo ? <VideoPauseIcon /> : <VideoPlayIcon />}
              action={toggleBackgroundVideo}
            />
          </motion.div>
        </ControlsWrapper>
      </VideoLibraryNavigationControlsContainer>

      <VideoGrid videoLibrary={videoLibrary} animationKey={animationKey} />
    </ContentWrapper>
  );
}
