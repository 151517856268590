import { SectorStory } from "@/types/sectors";
import { useQuery } from "@tanstack/react-query";
import StoryblokClient from "storyblok-js-client";

interface StoryblokResponse {
  stories: SectorStory[];
}

const Storyblok = new StoryblokClient({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN,
});

const fetchAllCollections = async (): Promise<SectorStory[]> => {
  try {
    const response = await Storyblok.get("cdn/stories/", {
      version: process.env.NEXT_PUBLIC_PRODUCTION_SITE === "true" ? "published" : "draft",
      filter_query: {
        component: {
          in: "sectorPage",
        },
      },
    });

    const typedResponse = response.data as StoryblokResponse;
    return typedResponse.stories;
  } catch (error) {
    console.error("Error fetching sector pages:", error);
    throw error;
  }
};

export const useAllSbSectors = () => {
  return useQuery<SectorStory[], Error>({
    queryKey: ["allSbSectorPages"],
    queryFn: fetchAllCollections,
  });
};
