import styled, { css } from "styled-components";
import ArrowRight from "@/assets/svg/arrowRight.svg";
import { useRouter } from "next/router";
import IconRenderer from "@/components/Shared/IconRenderer";
import { CtaThemeOption, ctaThemeProvider } from "@/utils/ThemeProvider";
import mq from "@/utils/mq";
import parseStoryblokLink from "@/utils/parseStoryblokLink";
import Link from "next/link";

const LabelLink = styled.button<{ $color?: string }>`
  color: ${(props) => props.$color};
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  border: none;
  outline: none;
  box-shadow: none;
  background: none;
  cursor: pointer;
  padding: 0;
  transition: all 0.3s var(--ease-in-out-circ);
`;

const Label = styled.span`
  font-size: 14px;
  white-space: nowrap;
  text-decoration: none;
  line-height: 1.25rem;
  font-weight: 500;
  user-select: none;
  ${mq.mobile(css`
    font-size: 12px;
  `)};
`;

const ArrowContainer = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: inherit;
  svg {
    color: inherit;
    width: 100%;
    height: 100%;
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  color: inherit;
`;

export default function LabelCta({
  label,
  link,
  color,
  theme,
  rawLink,
  rawLinkTarget,
  includeArrow,
  iconPosition = "hidden",
  icon,
  action,
  colorOverride,
}: {
  label: string;
  link?: any;
  color: string;
  rawLink?: any;
  includeArrow?: boolean;
  theme?: CtaThemeOption;
  rawLinkTarget?: any;
  iconPosition?: "hidden" | "left" | "right";
  action?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: React.ReactNode;
  colorOverride?: boolean;
}) {
  const router = useRouter();
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (action) {
      action(e);
    }
    if (rawLink) {
      if (rawLinkTarget === "_blank") {
        window.open(rawLink, "_blank");
        return;
      }
      router.push(rawLink);
    }
    if (link) {
      if (link.target === "_blank") {
        window.open(parseStoryblokLink(link), "_blank");
        return;
      }

      router.push(parseStoryblokLink(link));
    }
  };

  return (
    <LabelLinkWrapper link={link} rawLink={rawLink} rawLinkTarget={rawLinkTarget}>
      <LabelLink onClick={handleClick} $color={colorOverride ? "#fff" : ctaThemeProvider(theme)}>
        {iconPosition === "left" && icon && (
          <IconContainer style={{ marginRight: "3px" }}>
            <IconRenderer icon={icon} />
          </IconContainer>
        )}
        <Label>{label}</Label>
        {iconPosition === "right" && icon && (
          <IconContainer style={{ marginLeft: "3px" }}>
            <IconRenderer icon={icon} />
          </IconContainer>
        )}
        {iconPosition !== "right" && includeArrow && (
          <ArrowContainer>
            <ArrowRight />
          </ArrowContainer>
        )}
      </LabelLink>
    </LabelLinkWrapper>
  );
}

const LabelLinkWrapper = ({ link, rawLink, rawLinkTarget, children }) => {
  if (link) {
    return (
      <Link href={parseStoryblokLink(link)} target={link.target} style={{ textDecoration: "none" }}>
        {children}
      </Link>
    );
  }
  if (rawLink) {
    return (
      <a href={rawLink} target={rawLinkTarget} style={{ textDecoration: "none" }}>
        {children}
      </a>
    );
  }
  return <>{children}</>;
};
