// components/VideoLibrary/navigation/VideoFilter.tsx
import React, { useState, useCallback } from "react";
import styled, { css } from "styled-components";
import {
  useFloating,
  offset,
  flip,
  shift,
  useClick,
  useInteractions,
  useTransitionStyles,
  autoUpdate,
  useDismiss,
} from "@floating-ui/react";
import { useVideoPlayerStore } from "../store/videoPlayerStore";
import FilterIcon from "@/assets/svg/videoFilter.svg";
import { useVideoLibraryCategories } from "@/hooks/useVideoLibraryCategories";
import mq from "@/utils/mq";
import { AnimatePresence, cubicBezier, easeIn, easeOut, motion } from "motion/react";
import { SPRING_CONFIG } from "@/constants";

// Styled components
const ControlButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  padding: 0 8px;
  border-radius: 8px;
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  outline: none;
  gap: 8px;
  position: relative;
  transition:
    background 0.2s ease-in-out,
    transform 0.2s ease-in color 0.2s ease-in-out;
  color: #8a8f98;
  &:hover {
    transition:
      background 0.2s ease-in-out,
      transform 0.2s ease-in color 0.2s ease-in-out;
    background: hsla(0, 0%, 100%, 0.16);
    color: #f7f8f8;
  }
  ${mq.mobile(css`
    border: 1px solid hsla(0, 0%, 100%, 0.05);
  `)};
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const Label = styled.span`
  font-size: 12px;
  line-height: 17px;
`;

const DropdownContainer = styled(motion.div)`
  z-index: 1000;
  width: fit-content;
  overflow: hidden;
`;

const DropdownInnerContainer = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr;
  gap: 4px;
  padding: 8px;
  height: 0;
  min-height: 0;
  background: #141a1a;
  border: 1px solid #1f2626;
  border-radius: 8px;
  min-width: 200px;
`;

const DropdownOption = styled(motion.button)<{ $isSelected: boolean }>`
  width: 100%;
  padding: 8px 12px;
  background: ${(props) => (props.$isSelected ? "#1F2626" : "transparent")};
  border: none;
  border-radius: 4px;
  color: ${(props) => (props.$isSelected ? "#F7F8F8" : "#8A8F98")};
  font-size: 12px;
  line-height: 17px;
  text-align: left;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;
  &:hover {
    background: #1f2626;
    color: #f7f8f8;
  }
`;

const containerVariants = {
  hidden: {
    height: 0,
    transition: {
      staggerChildren: 0.01,
      duration: 0.25,
      delay: 0,
    },
  },
  visible: {
    height: "auto",
    transition: {
      staggerChildren: 0.01,
      duration: 0.25,
      delay: 0,
    },
  },
};

const itemVariants = {
  hidden: {
    opacity: 0,
    y: 5,
    transition: {
      duration: 0.2,
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      //duration: 0.32,
      //ease: cubicBezier(0.4, 0, 0.6, 1),
    },
  },
};

export default function VideoFilter() {
  const [isOpen, setIsOpen] = useState(false);
  const { data: categories } = useVideoLibraryCategories();
  const { filterCategory, setFilterCategory } = useVideoPlayerStore();

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "bottom-start",
    middleware: [
      offset(8),
      flip({
        fallbackAxisSideDirection: "end",
        padding: 8,
      }),
      shift({ padding: 8 }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context, {
    outsidePressEvent: "mousedown", // Close on outside click
    escapeKey: true, // Close on Escape key press
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
    initial: {
      opacity: 0,
      transform: "scale(0.95)",
    },
    open: {
      opacity: 1,
      transform: "scale(1)",
    },
    close: {
      opacity: 0,
      transform: "scale(0.95)",
    },
  });

  const handleSelect = (category: string | null) => {
    setFilterCategory(category);
    setIsOpen(false);
  };

  // Get the display label for the selected category
  const getDisplayLabel = () => {
    if (!filterCategory) return "Filter Videos";
    if (categories) {
      const selectedCategory = categories.find((cat) => cat.value === filterCategory);
      return selectedCategory ? selectedCategory.name : "Filter Videos";
    }
    return "Filter Videos";
  };

  return (
    <>
      <ControlButton ref={refs.setReference} {...getReferenceProps()}>
        <IconContainer>
          <FilterIcon />
        </IconContainer>
        <Label>{getDisplayLabel()}</Label>
      </ControlButton>
      <AnimatePresence>
        {isMounted && (
          <DropdownContainer
            ref={refs.setFloating}
            style={{
              ...transitionStyles,
              ...floatingStyles,
            }}
            {...getFloatingProps()}>
            <DropdownInnerContainer initial='hidden' animate='visible' exit='hidden' variants={containerVariants}>
              <DropdownOption
                variants={itemVariants}
                $isSelected={filterCategory === null}
                onClick={() => handleSelect(null)}>
                All Videos
              </DropdownOption>
              {categories?.map((category) => (
                <DropdownOption
                  variants={itemVariants}
                  key={category.id}
                  $isSelected={filterCategory === category.value}
                  onClick={() => handleSelect(category.value)}>
                  {category.name}
                </DropdownOption>
              ))}
            </DropdownInnerContainer>
          </DropdownContainer>
        )}
      </AnimatePresence>
    </>
  );
}
