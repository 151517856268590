// Define a type for the link object
type LinkObject = {
  id: string;
  url: string;
  target?: string;
  linktype: "url" | "asset" | "email" | "story";
  fieldtype: string;
  cached_url: string;
  email?: string;
  anchor?: string;
};

// Utility to handle URL links
function parseUrlLink(link: LinkObject): string {
  const anchor = link.anchor ? link.anchor : link.url.includes("#") ? link.url.substring(link.url.indexOf("#")) : "";
  const baseUrl = link.url.includes("#") ? link.url.substring(0, link.url.indexOf("#")) : link.url;

  if (baseUrl === "home" || baseUrl === "/de/home") {
    return "/" + anchor; // Correctly append anchor if any
  }

  return link.url; // Return the original URL if no special case
}

// Utility to handle Asset links
function parseAssetLink(link: LinkObject): string {
  return link.url; // Directly return the URL of the asset
}

// Utility to handle Email links
function parseEmailLink(link: LinkObject): string {
  return `mailto:${link.email || link.url}`; // Create a mailto link
}

// Utility to handle Story links (internal links)
function parseStoryLink(link: LinkObject): string {
  if (link.cached_url === "home" || link.cached_url === "/de/home") {
    return "/"; // Special handling for "/home" to "/"
  }

  return link.cached_url; // Use cached_url for internal navigation
}

// Main function to parse links
function parseStoryblokLink(link: LinkObject): string {
  switch (link?.linktype) {
    case "url":
      return parseUrlLink(link);
    case "asset":
      return parseAssetLink(link);
    case "email":
      return parseEmailLink(link);
    case "story":
      return parseStoryLink(link);
    default:
      return "";
  }
}

export default parseStoryblokLink;
