// hooks/useVideoNavigation.ts
import { useCallback, useEffect, useRef } from "react";
import { useRouter, usePathname } from "next/navigation";
import { useVideoPreloading } from "./useVideoPreloading";
import { getCorsSafeUrl } from "@/components/VideoLibrary/utils/videoHelpers";

// Type for navigation state
interface NavigationState {
  isNavigating: boolean;
  targetPath: string | null;
  lastNavigationStart: number;
  readyForNavigation: boolean;
  navigationCallbacks: Set<(targetPath: string) => void>;
}

// Create a singleton navigation state that persists between renders
const navigationState: NavigationState = {
  isNavigating: false,
  targetPath: null,
  lastNavigationStart: 0,
  readyForNavigation: false,
  navigationCallbacks: new Set(),
};

/**
 * Hook for managing video-related navigation with asset preloading
 */
export function useVideoNavigation() {
  const router = useRouter();
  const pathname = usePathname();
  const lastPathRef = useRef(pathname);
  const { preloadVideoAssets, isPreloaded } = useVideoPreloading();

  // Register a callback to be called when navigation starts
  const registerNavigationCallback = useCallback((callback: (targetPath: string) => void) => {
    navigationState.navigationCallbacks.add(callback);

    return () => {
      navigationState.navigationCallbacks.delete(callback);
    };
  }, []);

  // Trigger navigation callbacks
  const triggerNavigationCallbacks = useCallback((targetPath: string) => {
    navigationState.navigationCallbacks.forEach((callback) => {
      try {
        callback(targetPath);
      } catch (error) {
        console.error("Error in navigation callback:", error);
      }
    });
  }, []);

  // Navigate to a path with preloading
  const navigateWithPreloading = useCallback(
    async (targetPath: string, assetUrls?: { videoSrc?: string; posterSrc?: string }) => {
      // Don't navigate if already on this path
      if (targetPath === pathname) return;

      // Mark that we're navigating
      navigationState.isNavigating = true;
      navigationState.targetPath = targetPath;
      navigationState.lastNavigationStart = Date.now();
      navigationState.readyForNavigation = false;

      // Trigger navigation callbacks
      triggerNavigationCallbacks(targetPath);

      // Scroll to top smoothly when navigating
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      // Check if assets need to be preloaded
      if (assetUrls && (assetUrls.videoSrc || assetUrls.posterSrc)) {
        const videoPreloaded = !assetUrls.videoSrc || isPreloaded(assetUrls.videoSrc, "video");
        const posterPreloaded = !assetUrls.posterSrc || isPreloaded(assetUrls.posterSrc, "image");

        // If assets are already preloaded, navigate immediately
        if (videoPreloaded && posterPreloaded) {
          navigationState.readyForNavigation = true;
          setTimeout(() => router.push(targetPath), 300); // Short delay for animations
        } else {
          // Preload assets first
          try {
            router.prefetch(targetPath);
            await preloadVideoAssets(getCorsSafeUrl(assetUrls.videoSrc) || "", getCorsSafeUrl(assetUrls.posterSrc));
            navigationState.readyForNavigation = true;
            router.push(targetPath);
          } catch (error) {
            console.error("Error during preloading:", error);
            // Navigate anyway after a timeout if preloading fails
            setTimeout(() => router.push(targetPath), 500);
          }
        }
      } else {
        // No assets to preload, navigate immediately
        router.prefetch(targetPath);
        setTimeout(() => router.push(targetPath), 300);
      }
    },
    [pathname, router, preloadVideoAssets, isPreloaded, triggerNavigationCallbacks],
  );

  // Set up global click handler to intercept navigation
  useEffect(() => {
    const handleLinkClick = (e: MouseEvent) => {
      // Find if the click was on a link or inside a link
      let target = e.target as HTMLElement | null;
      let linkElement: HTMLAnchorElement | null = null;

      while (target) {
        if (target.tagName === "A") {
          linkElement = target as HTMLAnchorElement;
          break;
        }
        target = target.parentElement;
      }

      if (linkElement) {
        const href = linkElement.getAttribute("href");

        // Only handle internal navigation
        if (href && href.startsWith("/") && !href.startsWith("//") && !linkElement.hasAttribute("target")) {
          // This is an internal navigation link
          const currentPath = window.location.pathname;

          // If navigating to a different path
          if (href !== currentPath) {
            // Prevent default navigation
            e.preventDefault();

            // Handle with our custom navigation
            navigateWithPreloading(href);
          }
        }
      }
    };

    // Add global click listener
    document.addEventListener("click", handleLinkClick);

    return () => {
      document.removeEventListener("click", handleLinkClick);
    };
  }, [navigateWithPreloading]);

  // Handle path changes - detect when navigation completes
  useEffect(() => {
    if (lastPathRef.current !== pathname) {
      // We've arrived at the new page
      navigationState.isNavigating = false;
      navigationState.targetPath = null;
      navigationState.readyForNavigation = false;
    }

    // Update last path
    lastPathRef.current = pathname;
  }, [pathname]);

  return {
    navigateWithPreloading,
    registerNavigationCallback,
    isNavigating: navigationState.isNavigating,
    targetPath: navigationState.targetPath,
  };
}
