// components/VideoLibrary/navigation/VideoGrid.tsx
import React from "react";
import styled, { css } from "styled-components";
import { AnimatePresence, motion, Variants } from "motion/react";
import { useVideoPlayerStore } from "../store/videoPlayerStore";
import VideoThumbnail from "./VideoThumbnail";
import mq from "@/utils/mq";

const VideoLibraryNavigationContainer = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  grid-gap: 32px;
  z-index: 10;
  width: 100%;
  padding-bottom: 64px;
  position: relative;
  ${mq.desktopSmall(css`
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  `)};
`;

// Animation variants
const videoContainerVariants: Variants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.06,
      delay: 0.3,
    },
  },
};

interface VideoGridProps {
  videoLibrary: Array<{
    content: any;
    full_slug: string;
    name: string;
  }>;
  animationKey?: number;
}

/**
 * Grid component for displaying video thumbnails
 * with filtering and animation
 */
export default function VideoGrid({ videoLibrary, animationKey = 0 }: VideoGridProps) {
  const { filterCategory } = useVideoPlayerStore();

  if (!videoLibrary) return null;

  // Filter videos by category if needed
  const filteredVideos = filterCategory
    ? videoLibrary.filter((video) => video.content.category === filterCategory)
    : videoLibrary;

  return (
    <VideoLibraryNavigationContainer
      key={animationKey}
      variants={videoContainerVariants}
      initial='hidden'
      animate='visible'>
      <AnimatePresence>
        {filteredVideos?.map((video) => <VideoThumbnail key={video.full_slug} video={video} />)}
      </AnimatePresence>
    </VideoLibraryNavigationContainer>
  );
}
