// hooks/useVideoPreloading.ts
import { useCallback, useRef, useEffect } from "react";

// Cache for preloaded assets
type PreloadCache = {
  images: Map<string, boolean>;
  videos: Map<string, boolean>;
};

// Global cache that persists between component instances
const preloadCache: PreloadCache = {
  images: new Map<string, boolean>(),
  videos: new Map<string, boolean>(),
};

/**
 * Hook for preloading video and image assets
 */
export function useVideoPreloading() {
  const preloadingInProgress = useRef(new Map<string, Promise<void>>());

  /**
   * Preload an image by creating a new Image object
   */
  const preloadImage = useCallback((src: string): Promise<void> => {
    // Return cached result immediately if already preloaded
    if (preloadCache.images.has(src)) {
      return Promise.resolve();
    }

    // Return existing promise if already in progress
    if (preloadingInProgress.current.has(src)) {
      return preloadingInProgress.current.get(src)!;
    }

    // Create a new preload promise
    const promise = new Promise<void>((resolve, reject) => {
      const img = new Image();

      img.onload = () => {
        preloadCache.images.set(src, true);
        preloadingInProgress.current.delete(src);
        resolve();
      };

      img.onerror = (err) => {
        preloadingInProgress.current.delete(src);
        reject(err);
      };

      img.src = src;
    });

    // Store the promise for deduplication
    preloadingInProgress.current.set(src, promise);
    return promise;
  }, []);

  /**
   * Preload a video by creating a video element and loading metadata
   */
  const preloadVideo = useCallback((src: string): Promise<void> => {
    // Return cached result immediately if already preloaded
    if (preloadCache.videos.has(src)) {
      return Promise.resolve();
    }

    // Return existing promise if already in progress
    if (preloadingInProgress.current.has(src)) {
      return preloadingInProgress.current.get(src)!;
    }

    // Create a new preload promise
    const promise = new Promise<void>((resolve, reject) => {
      const video = document.createElement("video");

      // Configure for efficient loading
      video.muted = true;
      video.preload = "metadata";
      video.crossOrigin = "anonymous";

      const onMetadataLoaded = () => {
        preloadCache.videos.set(src, true);
        preloadingInProgress.current.delete(src);

        // Clean up
        video.removeEventListener("loadedmetadata", onMetadataLoaded);
        video.removeEventListener("error", onError);

        // Don't remove immediately to allow browser caching
        setTimeout(() => {
          video.src = "";
          video.load();
          video.remove();
        }, 5000);

        resolve();
      };

      const onError = (err: Event) => {
        console.error(`Error preloading video ${src}:`, err);
        preloadingInProgress.current.delete(src);

        // Clean up
        video.removeEventListener("loadedmetadata", onMetadataLoaded);
        video.removeEventListener("error", onError);

        reject(err);
      };

      // Add event listeners
      video.addEventListener("loadedmetadata", onMetadataLoaded);
      video.addEventListener("error", onError);

      // Start loading
      video.src = src;
    });

    // Store the promise for deduplication
    preloadingInProgress.current.set(src, promise);
    return promise;
  }, []);

  /**
   * Check if an asset has been preloaded
   */
  const isPreloaded = useCallback((src: string, type: "image" | "video"): boolean => {
    return type === "image" ? preloadCache.images.has(src) : preloadCache.videos.has(src);
  }, []);

  /**
   * Preload a video and its poster image
   */
  const preloadVideoAssets = useCallback(
    async (videoSrc: string, posterSrc?: string): Promise<void> => {
      const promises: Promise<void>[] = [];

      if (videoSrc && !isPreloaded(videoSrc, "video")) {
        promises.push(
          preloadVideo(videoSrc).catch((err) => {
            console.error("Error preloading video:", err);
          }),
        );
      }

      if (posterSrc && !isPreloaded(posterSrc, "image")) {
        promises.push(
          preloadImage(posterSrc).catch((err) => {
            console.error("Error preloading poster image:", err);
          }),
        );
      }

      await Promise.all(promises);
    },
    [preloadVideo, preloadImage, isPreloaded],
  );

  // Clean up preloading promises on unmount
  useEffect(() => {
    return () => {
      preloadingInProgress.current.clear();
    };
  }, []);

  return {
    preloadImage,
    preloadVideo,
    preloadVideoAssets,
    isPreloaded,
    preloadCache,
  };
}
