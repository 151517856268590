const relations: string[] = [
  "blogCollectionSlider.blogCollection",
  "blogCollection.stories",
  "blogPost.primaryCollection",
  "blogAuthor.author",
  "blogPost.category",
  "blogPost.tags",
  "resourceCentreResource.tags",
  //"stories.tags",
];

export default relations;
