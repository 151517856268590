// components/VideoLibrary/ui/VideoLibraryControlButton.tsx
import mq from "@/utils/mq";
import React from "react";
import styled, { css } from "styled-components";

const ControlButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  padding: 0 8px;
  border-radius: 8px;
  background: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  outline: none;
  gap: 8px;
  transition:
    background 0.2s ease-in-out,
    transform 0.2s ease-in,
    color 0.2s ease-in-out;
  color: #8a8f98;
  &:hover {
    transition:
      background 0.2s ease-in-out,
      transform 0.2s ease-in,
      color 0.2s ease-in-out;
    background: hsla(0, 0%, 100%, 0.16);
    color: #f7f8f8;
  }
  ${mq.mobile(css`
    border: 1px solid hsla(0, 0%, 100%, 0.05);
  `)};
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const Label = styled.span`
  font-size: 12px;
  line-height: 17px;
`;

interface VideoLibraryControlButtonProps {
  label: string;
  icon?: React.ReactNode;
  action: () => void;
  style?: React.CSSProperties;
}

/**
 * Reusable control button for video library navigation
 */
export default function VideoLibraryControlButton({ label, icon, action, style }: VideoLibraryControlButtonProps) {
  return (
    <ControlButton onClick={action} style={style}>
      {icon && <IconContainer>{icon}</IconContainer>}
      <Label>{label}</Label>
    </ControlButton>
  );
}
