// store/videoPlayerStore.ts
import { create } from "zustand";
import React, { useCallback, useEffect } from "react";
import { VideoPlayerState } from "../types";

interface VideoPlayerStore extends VideoPlayerState {
  open: () => void;
  close: () => void;
  toggle: () => void;
  setPlayBackgroundVideo: (playBackgroundVideo: boolean) => void;
  setShouldAutoPlay: (shouldAutoPlay: boolean) => void;
  setFilterCategory: (category: string | null) => void;
  reset: () => void;
}

/**
 * Store for managing video player state across the application
 */
export const useVideoPlayerStore = create<VideoPlayerStore>((set) => ({
  // State
  isOpen: false,
  playBackgroundVideo: true,
  shouldAutoPlay: true,
  filterCategory: null,

  // Actions
  open: () => set({ isOpen: true }),
  close: () => set({ isOpen: false }),
  toggle: () => set((state) => ({ isOpen: !state.isOpen })),
  setPlayBackgroundVideo: (playBackgroundVideo: boolean) => set({ playBackgroundVideo }),
  setShouldAutoPlay: (shouldAutoPlay: boolean) => set({ shouldAutoPlay }),
  setFilterCategory: (category: string | null) => set({ filterCategory: category }),

  // Reset state
  reset: () =>
    set({
      isOpen: false,
      playBackgroundVideo: true,
      shouldAutoPlay: true,
      filterCategory: null,
    }),
}));

// Hook for checking reduced motion preference
export function useReducedMotionCheck(callback: (prefersReducedMotion: boolean) => void) {
  React.useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");

    // Initial check
    callback(mediaQuery.matches);

    // Add listener for changes
    const handler = (e: MediaQueryListEvent) => {
      callback(e.matches);
    };

    mediaQuery.addEventListener("change", handler);
    return () => mediaQuery.removeEventListener("change", handler);
  }, [callback]);
}
