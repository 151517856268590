// hooks/useVideoLibraryCategories.ts
import { useQuery } from "@tanstack/react-query";

interface VideoLibraryCategory {
  id: number | string;
  name: string;
  value: string | null;
  dimension_value?: string | null;
}

interface StoryblokDatasourceResponse {
  datasource_entries: VideoLibraryCategory[];
  cv: number;
}

/**
 * Fetches video categories from Storyblok datasource
 */
const fetchVideoLibraryCategories = async (): Promise<VideoLibraryCategory[]> => {
  try {
    // Add current Unix timestamp as cv parameter for cache busting
    const timestamp = Math.floor(Date.now() / 1000);
    const response = await fetch(
      `https://api.storyblok.com/v2/cdn/datasource_entries?datasource=video-library-categories&token=${process.env.NEXT_PUBLIC_STORYBLOK_TOKEN}&cv=${timestamp}`,
    );

    if (!response.ok) {
      throw new Error("Failed to fetch video library categories");
    }

    const data = (await response.json()) as StoryblokDatasourceResponse;
    return data.datasource_entries;
  } catch (error) {
    console.error("Error fetching video library categories:", error);
    throw error;
  }
};

/**
 * Hook to fetch and manage video library categories
 */
export const useVideoLibraryCategories = () => {
  return useQuery<VideoLibraryCategory[], Error>({
    queryKey: ["videoLibraryCategories"],
    queryFn: fetchVideoLibraryCategories,
  });
};
