import Image from "next/image";
import { useEffect, useState, useMemo } from "react";
import styled, { css, keyframes } from "styled-components";

interface LazyImageProps {
  src: string;
  alt: string;
  width?: number;
  height?: number;
  quality?: number;
  priority?: boolean;
  fill?: boolean;
  style?: React.CSSProperties;
  parallax?: boolean;
  sizes?: string;
  parallaxSpeed?: number;
  layout?: "fixed" | "constrained" | "fullWidth";
  blurHash?: string;
  aspectRatio?: number;
  blur?: boolean;
  allowOverflow?: boolean;
  loading?: "lazy" | "eager";
  containerStyles?: React.CSSProperties;
}

interface ImageContainerProps {
  $blur?: boolean;
  $allowOverflow?: boolean;
}

const fadeIn = keyframes`
  from {
    filter: blur(20px);
  }
  to {
    filter: blur(0px);
  }
`;

const ImageContainer = styled.div<ImageContainerProps>`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: ${(props) => (props.$allowOverflow ? "visible" : "hidden")};
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    !props.$blur &&
    css`
      animation: ${fadeIn} 0.2s ease-in-out;
    `}
  ${(props) =>
    props.$blur &&
    css`
      filter: blur(20px);
    `}
`;

const StyledImage = styled(Image)`
  height: 100%;
  overflow: hidden;
`;

const extractStoryblokDimensions = (url: string): { width: number | undefined; height: number | undefined } => {
  try {
    const segments = url.split("/");
    const dimensionSegment = segments[5];

    if (!dimensionSegment || !dimensionSegment.includes("x")) {
      return { width: undefined, height: undefined };
    }

    const [width, height] = dimensionSegment.split("x")?.map((dim) => {
      const parsed = parseInt(dim, 10);
      return isNaN(parsed) ? undefined : parsed;
    });

    return { width, height };
  } catch {
    return { width: undefined, height: undefined };
  }
};

const generateStoryblokImageUrl = (baseUrl: string, width?: number, height?: number, quality: number = 70): string => {
  if (!width || !height) {
    return `${baseUrl}/m/filters:quality(${quality})`;
  }
  return `${baseUrl}/m/${width}x${height}/filters:quality(${quality})`;
};

export default function NextLazyImage({
  src,
  alt,
  width,
  height,
  sizes,
  quality = 70,
  priority = false,
  fill = false,
  parallax = false,
  loading = "lazy",
  parallaxSpeed = 0.1,
  layout,
  style,
  blurHash,
  aspectRatio,
  allowOverflow = false,
  containerStyles,
  blur = true,
  ...props
}: LazyImageProps) {
  const [shouldBlur, setShouldBlur] = useState(true);
  const [loaded, setLoaded] = useState(false);

  const isStoryblokImage = useMemo(() => {
    try {
      return src?.includes("https://a.storyblok.com") ?? false;
    } catch {
      return false;
    }
  }, [src]);

  const { imageSrc, placeholder, finalWidth, finalHeight } = useMemo(() => {
    if (!src) {
      return {
        imageSrc: "",
        placeholder: "",
        finalWidth: width,
        finalHeight: height,
      };
    }

    if (isStoryblokImage) {
      const { width: sbWidth, height: sbHeight } = extractStoryblokDimensions(src);

      return {
        imageSrc: generateStoryblokImageUrl(src, width || sbWidth, height || sbHeight, quality),
        placeholder: `${src}/m/filters:quality(1)blur(100)`,
        finalWidth: width || sbWidth,
        finalHeight: height || sbHeight,
      };
    }

    // For non-Storyblok images
    return {
      imageSrc: src,
      placeholder: `/_next/image?url=${encodeURIComponent(src)}&w=16&q=1`,
      finalWidth: width,
      finalHeight: height,
    };
  }, [src, width, height, quality, isStoryblokImage]);

  // Early return if no source is provided
  if (!src) return null;

  // Simplified loader function
  const loader = ({ src }: { src: string }) => src;

  const handleImageLoad = () => {
    setShouldBlur(false);
    setLoaded(true);
  };

  return (
    <ImageContainer $allowOverflow={allowOverflow} $blur={shouldBlur} style={{ ...containerStyles }}>
      <StyledImage
        src={imageSrc}
        alt={alt}
        sizes={sizes}
        width={fill ? undefined : finalWidth || undefined}
        height={fill ? undefined : finalHeight || undefined}
        fill={fill}
        layout={layout || (fill ? "fullWidth" : "constrained")}
        onLoad={handleImageLoad}
        quality={quality}
        placeholder={blur ? "blur" : "empty"}
        //loading={priority ? "eager" : loading}
        blurDataURL={placeholder}
        priority={priority}
        style={{
          objectFit: "cover",
          objectPosition: "center",
          ...style,
        }}
        {...props}
      />
    </ImageContainer>
  );
}
