// components/VideoLibrary/navigation/VideoThumbnail.tsx
import React, { useRef } from "react";
import styled from "styled-components";
import { motion } from "motion/react";
import { format } from "date-fns";
import NextLazyImage from "@/components/Core/NextLazyImage";
import { usePathname } from "next/navigation";
import { useVideoNavigation } from "../hooks/useVideoNavigation";
import { useVideoPlayerStore } from "../store/videoPlayerStore";
import { Video } from "../types";

interface VideoThumbnailProps {
  video: {
    content: Video;
    full_slug: string;
    name: string;
  };
}

const VideoLibraryNavigationItemContainer = styled.a`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  position: relative;
  width: 100%;
  cursor: pointer;
`;

const Thumbnail = styled.div`
  aspect-ratio: 16/9;
  width: 100%;
  background-color: #141a1a;
  overflow: hidden;
  border-radius: 8px;
  position: relative;
`;

const Meta = styled.div`
  margin-top: 8px;
`;

const Title = styled.h3`
  color: #d0d6e0;
  font-size: 14px;
  margin: 0;
  font-weight: 500;
  line-height: 18px;
  width: 100%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Description = styled.p`
  color: #8a8f98;
  margin: 0;
  font-size: 12px;
  line-height: 12px;
  width: 100%;
  margin-top: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

// Animation variants
const videoItemVariants = {
  hidden: { opacity: 0, y: 15, filter: "blur(20px)" },
  visible: {
    opacity: 1,
    y: 0,
    filter: "blur(0px)",
    transition: { type: "spring", stiffness: 100, damping: 15 },
  },
};

/**
 * VideoThumbnail component for displaying a single video in the grid
 */
export default function VideoThumbnail({ video }: VideoThumbnailProps) {
  const pathname = usePathname();
  const { open: openPlayer } = useVideoPlayerStore();
  const { navigateWithPreloading } = useVideoNavigation();
  const hasTriggeredPreload = useRef(false);

  const isCurrentVideo = pathname === `/${video.full_slug}`;

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();

    // If this is the current video, just open the player
    if (isCurrentVideo) {
      openPlayer();
      return;
    }

    // Scroll to top smoothly when selecting a new video
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    // For non-current videos, navigate with preloading
    const targetPath = `/${video.full_slug}`;
    navigateWithPreloading(targetPath, {
      videoSrc: video.content.preview?.filename,
      posterSrc: video.content.poster?.filename,
    });
  };

  const handleMouseEnter = () => {
    // Don't preload if it's the current video or if we've already triggered preload
    if (isCurrentVideo || hasTriggeredPreload.current) return;

    // Mark that we've triggered preload for this item
    hasTriggeredPreload.current = true;

    // Only preload assets on hover, but don't navigate
    if (video.content.preview?.filename || video.content.poster?.filename) {
      // Simple preloading without the navigation
      // For images
      if (video.content.poster?.filename) {
        const img = new Image();
        img.src = video.content.poster.filename;
      }

      // For videos, we can preload using a video element
      if (video.content.preview?.filename) {
        const videoElement = document.createElement("video");
        videoElement.preload = "metadata";
        videoElement.src = video.content.preview.filename;

        // Clean up after preloading
        videoElement.onloadedmetadata = () => {
          videoElement.remove();
        };
      }
    }
  };

  return (
    <motion.div variants={videoItemVariants} key={video.full_slug} layout>
      <VideoLibraryNavigationItemContainer
        href={`/${video.full_slug}`}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}>
        <Thumbnail>
          <NextLazyImage
            src={video.content.thumbnail.filename}
            alt={video.name}
            fill
            blur={false}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            quality={70}
          />
        </Thumbnail>
        <Meta>
          <Title>{video.content.title}</Title>
          <Description>
            {format(new Date(video.content.publishDate), "MMM d, yyyy")} · {video.content.lengthMinutes} min
          </Description>
        </Meta>
      </VideoLibraryNavigationItemContainer>
    </motion.div>
  );
}
